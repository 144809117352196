let route = '/api/zoho/';
const actions = {

    refreshAccessToken({}) {
        return new Promise((resolve, reject) => {
            axios.post(`${route}refresh-token?refresh_token=1000.3459060599978dec418a4388f6ac3ace.5bcd84eb03ee12fcabaf14b98abfaa8a`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    createContact({}, data) {
        return new Promise((resolve, reject) => {
            axios.post(`${route}create-contact`, data, {
                    headers: {
                        'Accept': 'application/json',
                        'Zoho-Authorization': localStorage.getItem('Zoho-Authorization')
                    }
                })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    checkAuthorized({}) {
        return new Promise((resolve, reject) => {
            axios.post(`${route}check-authorized`, null, {
                    headers: {
                        'Accept': 'application/json',
                        'Zoho-Authorization': localStorage.getItem('Zoho-Authorization')
                    }
                })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getChartOfAccounts({}) {
        return new Promise((resolve, reject) => {
            axios.post(`${route}get-chart-of-accounts`, null, {
                    headers: {
                        'Accept': 'application/json',
                        'Zoho-Authorization': localStorage.getItem('Zoho-Authorization')
                    }
                })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    getDepositToAccounts({}) {
        return new Promise((resolve, reject) => {
            axios.post(`${route}get-deposit-to-accounts`, null, {
                    headers: {
                        'Accept': 'application/json',
                        'Zoho-Authorization': localStorage.getItem('Zoho-Authorization')
                    }
                })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    getPaymentModes({}) {
        return new Promise((resolve, reject) => {
            axios.post(`${route}get-payment-modes`, null, {
                    headers: {
                        'Accept': 'application/json',
                        'Zoho-Authorization': localStorage.getItem('Zoho-Authorization')
                    }
                })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

}
export default {
    namespaced: true,
    actions,
}